import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import AvailabilityInfo from 'Component/AvailabilityInfo';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { Product } from 'Component/Product/Product.component';
import ProductPrice from 'Component/ProductPrice';
import ProductPublisherOrProducer from 'Component/ProductPublisherOrProducer';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import { getBooleanValue } from 'Util/Data';
import { ADD_TO_CART, getCodazonLabels, getProductAuthor } from 'Util/Product';
import { getPrice } from 'Util/Product/Extract';
import { getOmnibusPriceInfo, getProductIdentificationAttribs, verifyOmnibus } from 'Util/Product/Product';
import { magentoProductWishlistTransform } from 'Util/Product/Transform';

import {
    BANNER_VARIANT,
    BANNER_WIDGET_CLASS,
    BANNER_WIDGET_WITOUT_BUTTON_CLASS,
    GRID_VARIANT,
} from './SingleProductWidget.config';

import './SingleProductWidget.style';

/** @namespace SwiatKsiazkiBasic/Component/SingleProductWidget/Component */
export class SingleProductWidget extends Product {
    static propTypes = {
        item: PropTypes.object,
        isLoading: PropTypes.bool,
        title: PropTypes.object,
    };

    className = 'SingleProductWidget';

    renderPrice() {
        const {
            item: { price_range: priceRange, type_id: type, dynamic_price: dynamicPrice, omnibus },
            classVariant,
            productDisplay,
            options,
        } = this.props;
        const { price: isPriceVisible } = options;
        const { is_discount: isOmnibus } = omnibus || {};

        if (!getBooleanValue(isPriceVisible)) {
            return null;
        }

        return (
            <ProductPrice
                price={getPrice(priceRange, dynamicPrice, {}, type)}
                mods={{ type: 'regular' }}
                isInBanner={
                    productDisplay === BANNER_VARIANT ||
                    classVariant === BANNER_WIDGET_CLASS ||
                    classVariant === BANNER_WIDGET_WITOUT_BUTTON_CLASS
                }
                isOmnibus={isOmnibus}
            />
        );
    }

    renderName() {
        const {
            item: { name, url },
            options,
        } = this.props;
        const { name: isNameVisible } = options;

        if (!getBooleanValue(isNameVisible)) {
            return null;
        }

        return (
            <Link to={url} block="SingleProductWidget" elem="Name">
                {name}
            </Link>
        );
    }

    renderPublisherOrProducer() {
        const { item } = this.props;

        return <ProductPublisherOrProducer className={this.className} product={item} />;
    }

    renderAuthor() {
        const { item, options } = this.props;
        const { name: isNameVisible } = options;

        if (!getBooleanValue(isNameVisible)) {
            return null;
        }

        return getProductAuthor(item, this.className);
    }

    renderAddToCartButton() {
        const { item, addToCart, options } = this.props;
        const { addtocart: isAddToCartVisible } = options;

        if (!getBooleanValue(isAddToCartVisible)) {
            return null;
        }

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                product={item}
                variant="outline"
                label="DODAJ DO KOSZYKA"
            />
        );
    }

    renderWishlistButton() {
        const { isWishlistEnabled, options, item } = this.props;
        const { wishlist: isWishlistVisible } = options;

        if (!isWishlistEnabled || !getBooleanValue(isWishlistVisible)) {
            return null;
        }

        return (
            <ProductWishlistButton
                magentoProduct={magentoProductWishlistTransform(ADD_TO_CART, item)}
                mix={{
                    block: this.className,
                    elem: 'WishListButton',
                }}
            />
        );
    }

    getPictureSizes() {
        const { productDisplay, classVariant } = this.props;

        if (
            productDisplay === BANNER_VARIANT ||
            classVariant === BANNER_WIDGET_CLASS ||
            classVariant === BANNER_WIDGET_WITOUT_BUTTON_CLASS
        ) {
            return { width: '162', height: '216' };
        }

        return { width: '230', height: '276' };
    }

    renderPicture() {
        const {
            item: { id, name, url, image },
            options,
        } = this.props;
        const { image: isImageVisible } = options;

        if (!getBooleanValue(isImageVisible)) {
            return null;
        }

        return (
            <Link to={url} block="SingleProductWidget" elem="PictureWrapper">
                <Image src={image?.url} alt={name} ratio="custom" isPlaceholder={!id} {...this.getPictureSizes()} />
            </Link>
        );
    }

    renderTitle() {
        const { title } = this.props;
        const { content, align, line, linePosition, tag } = title || {};
        const TitleTag = tag || 'h2';

        if (!content) {
            return null;
        }

        return (
            <div
                block="SingleProductWidget"
                elem="TitleContainer"
                className={`mgz-block-heading ${align ? `mgz-block-heading-align-${align}` : ''} ${
                    getBooleanValue(line)
                        ? `mgz-block-heading-line ${
                              linePosition ? `mgz-block-heading-line-position-${linePosition}` : ''
                          }`
                        : ''
                }`}
            >
                <TitleTag block="SingleProductWidget" elem="Title" className="title">
                    {content}
                </TitleTag>
            </div>
        );
    }

    renderProductLabels() {
        const { item } = this.props;

        return (
            <div block="SingleProductWidget" elem="LabelContent">
                {verifyOmnibus(
                    item,
                    getOmnibusPriceInfo(item, { alwaysShortMessage: true }),
                    getCodazonLabels(item, false, this.className)
                )}
            </div>
        );
    }

    renderShortDescriptionContent() {
        const {
            item: { short_description },
            options,
        } = this.props;
        const { html } = short_description || {};
        const { shortdescription: isShortDescriptionVisible } = options;

        if (!html || !getBooleanValue(isShortDescriptionVisible)) {
            return null;
        }

        const htmlWithItemProp = `<div itemProp="description">${html}</div>`;

        return (
            <div block="SingleProductWidget" elem="ShortDescription">
                <Html content={htmlWithItemProp} />
            </div>
        );
    }

    renderAvailabilityInfo() {
        const {
            options,
            item: { stock_item: { in_stock: inStock = true } = {} },
            item,
        } = this.props;
        const { show_availability_box: isShowAvailabilityBox } = options;

        if (!inStock || !getBooleanValue(isShowAvailabilityBox)) {
            return null;
        }

        return (
            <div block="SingleProductWidget" elem="AvailabilityInfo">
                <AvailabilityInfo product={item} inStock={inStock} />
            </div>
        );
    }

    renderContent() {
        return (
            <div block="SingleProductWidget" elem="Content">
                {this.renderPicture()}
                <div block="SingleProductWidget" elem="ContentWrapper">
                    {this.renderName()}
                    {this.renderAuthor()}
                    {this.renderPublisherOrProducer()}
                    {this.renderPrice()}
                    {this.renderProductLabels()}
                    {this.renderAvailabilityInfo()}
                    {this.renderShortDescriptionContent()}
                    {this.renderAddToCartButton()}
                </div>
                {this.renderWishlistButton()}
            </div>
        );
    }

    renderBannerTitle() {
        const { title } = this.props;
        const { content, tag } = title || {};
        const TitleTag = tag || 'h2';

        if (!content) {
            return null;
        }

        return (
            <TitleTag block="SingleProductWidget" elem="BannerTitle">
                {content}
            </TitleTag>
        );
    }

    renderBannerSubtitle() {
        const { subtitle } = this.props;

        return (
            <p block="SingleProductWidget" elem="BannerSubtitle">
                {subtitle}
            </p>
        );
    }

    renderBannerContent() {
        return (
            <>
                <div block="SingleProductWidget" elem="BannerContent">
                    {this.renderPicture()}
                    <div block="SingleProductWidget" elem="ContentWrapper">
                        {this.renderBannerTitle()}
                        {this.renderBannerSubtitle()}
                        {this.renderPrice()}
                        {this.renderShortDescriptionContent()}
                        {this.renderBannerAddToCartButton()}
                    </div>
                </div>
                {this.renderWishlistButton()}
            </>
        );
    }

    renderBannerAddToCartButton() {
        const { item, addToCart, classVariant, productDisplay, options } = this.props;
        const { addtocart: isAddToCartVisible } = options;

        if (
            (productDisplay !== BANNER_VARIANT && classVariant !== BANNER_WIDGET_CLASS) ||
            !getBooleanValue(isAddToCartVisible)
        ) {
            return null;
        }

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                product={item}
                variant="hollow"
                label="DODAJ DO KOSZYKA"
            />
        );
    }

    renderGridVariant() {
        return (
            <div block="SingleProductWidget" elem="GridContent">
                {this.renderPicture()}
                {this.renderName()}
                {this.renderAuthor()}
                {this.renderPublisherOrProducer()}
                {this.renderPrice()}
                {this.renderProductLabels()}
                {this.renderAvailabilityInfo()}
                {this.renderShortDescriptionContent()}
                {this.renderAddToCartButton()}
                {this.renderWishlistButton()}
            </div>
        );
    }

    render() {
        const { item, isLoading, backgroundColor, classVariant, productDisplay } = this.props;

        if (isLoading) {
            return <div block="SingleProductWidget" elem="Placeholder" />;
        }

        if (!isLoading && !Object.keys(item).length) {
            return null;
        }

        if (
            productDisplay === BANNER_VARIANT ||
            classVariant === BANNER_WIDGET_CLASS ||
            classVariant === BANNER_WIDGET_WITOUT_BUTTON_CLASS
        ) {
            return (
                <div
                    block="SingleProductWidget"
                    mods={{ isBannerVariant: true }}
                    style={{ backgroundColor }}
                    {...getProductIdentificationAttribs(item)}
                >
                    {this.renderBannerContent()}
                </div>
            );
        }

        if (productDisplay === GRID_VARIANT) {
            return (
                <div
                    block="SingleProductWidget"
                    mods={{ isGridVariant: true }}
                    {...getProductIdentificationAttribs(item)}
                >
                    {this.renderTitle()}
                    {this.renderGridVariant()}
                </div>
            );
        }

        return (
            <div block="SingleProductWidget" {...getProductIdentificationAttribs(item)}>
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        );
    }
}

export default SingleProductWidget;
