import lazy from '@loadable/component';
import PropTypes from 'prop-types';
import { cloneElement, PureComponent, Suspense } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import Breadcrumbs from 'Component/Breadcrumbs';
import ClickonometricsContainer from 'Component/Clickonometrics';
import { CLICKONOMETRICS_VARIANT } from 'Component/Clickonometrics/Clickonometrics.config';
import CmsBlockProvider from 'Component/CmsBlockProvider';
import CookiePopup from 'Component/CookiePopup';
import EdroneContainer from 'Component/Edrone';
import Footer from 'Component/Footer';
import {
    FOOTER_COPYRIGHT_CMS_ID,
    FOOTER_LINKS_CMS_ID,
    FOOTER_PAYMENTS_CMS_ID,
    FOOTER_SEO_CMS_ID,
    FOOTER_SOCIAL_ICONS_CMS_ID,
} from 'Component/Footer/Footer.config';
import Header from 'Component/Header';
import Loader from 'Component/Loader';
import { MOBILE_MENU_LINKS_CMS_ID } from 'Component/Menu/Menu.config';
import Meta from 'Component/Meta';
import {
    PRINT_ALL_INVOICES,
    PRINT_ALL_REFUNDS,
    PRINT_ALL_SHIPMENT,
    PRINT_INVOICE,
    PRINT_ORDER as PRINT_ORDER_REQUEST,
    PRINT_REFUND,
    PRINT_SHIPMENT,
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import NavigationTabs from 'Component/NavigationTabs';
import { NEWSLETTER_INFO_CMS_ID } from 'Component/Newsletter/Newsletter.config';
import NewsletterPopup from 'Component/NewsletterPopup';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import NotificationObserver from 'Component/NotificationObserver';
import OfflineNotice from 'Component/OfflineNotice';
import RestoreScrollContainer from 'Component/RestoreScroll';
import ScrollToTop from 'Component/ScrollToTop';
import WishListPriceAlertPopup from 'Component/WishListPriceAlertPopup';
import { BESTSELLERS_PAGE_URL } from 'Route/BestsellersPage/BestsellersPage.config';
import { HOMEPAGE_SLIDER_CMS_ID, HOMEPAGE_USP_CMS_ID } from 'Route/HomePage/HomePage.config';
import UrlRewrites from 'Route/UrlRewrites';
import {
    ACCOUNT_CONSENTS,
    ACCOUNT_CUSTOMER_CARD_ADD,
    ACCOUNT_CUSTOMER_CARDS,
    ACCOUNT_CUSTOMER_CARDS_LIST,
    ACCOUNT_REVIEWS,
    ADDRESS_BOOK,
    MY_DOWNLOADABLE,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION,
} from 'Type/Account.type';
import history from 'Util/History';
import { translateUrl } from 'Util/Url';

import {
    ACCOUNT_FORGOT_PASSWORD,
    ACCOUNT_FORGOT_PASSWORD_SUCCESS,
    ACCOUNT_SIDEBAR_PAGE,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BESTSELLERS_PAGE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CHECKOUT_TERMS_AND_CONDITIONS,
    CLICKONOMETRICS_CONTAINER,
    CMS_PAGE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    EDRONE_CONTAINER,
    FOOTER,
    GIFT_CARDS_ORDER_SUCCESS,
    HEADER,
    INVOICE_INFORMATION,
    LOGIN,
    LOGIN_AS_USER,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_CONSENTS,
    MY_ACCOUNT_CUSTOMER_CARD_ADD,
    MY_ACCOUNT_CUSTOMER_CARDS,
    MY_ACCOUNT_CUSTOMER_CARDS_LIST,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDER_REPAYMENT,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_REVIEWS,
    MY_ACCOUNT_REVIEWS_DETAILS_PAGE,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NEWSLETTER_POPUP,
    NOTIFICATION_LIST,
    NOTIFICATION_OBSERVER,
    PRINT_ORDER,
    SEARCH,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES,
    WISHLIST_PRICE_ALERT_POPUP,
} from './Router.config';

// Pages
export const CheckoutTermsAndConditionsPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Component/CheckoutTermsAndConditionsPopup')
);
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/CmsPage'));
export const BestsellersPage = lazy(() => import('Route/BestsellersPage'));
export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/Checkout'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/MyAccount'));
export const PasswordChangePage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/PasswordChangePage')
);
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/SearchPage'));
export const ConfirmAccountPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/ConfirmAccountPage')
);
export const MenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/MenuPage'));
export const WishlistShared = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/WishlistSharedPage')
);
export const ContactPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/ContactPage'));
export const CreateAccountPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/CreateAccount')
);
export const LoginAccountPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/LoginAccount')
);
export const ForgotPasswordPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/ForgotPassword')
);
export const SomethingWentWrong = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/SomethingWentWrong')
);
export const OrderPrintPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/OrderPrintPage')
);
export const ForgotPasswordSuccessPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/ForgotPasswordSuccess')
);
export const MyAccountSidebarPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/MyAccountSidebarPage')
);
export const GiftCardsOrderSuccess = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/GiftCardsOrderSuccess')
);
export const InvoiceInformation = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/InvoiceInformation')
);
export const LoginAsCustomer = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "pages" */ 'Route/LoginAsCustomer')
);

/** @namespace SwiatKsiazkiBasic/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace SwiatKsiazkiBasic/Component/Router/Component */
export class Router extends PureComponent {
    static propTypes = {
        isBigOffline: PropTypes.bool,
        isOnlyMainItems: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isBigOffline: false,
    };

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST,
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER,
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS,
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS,
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP,
        },
        {
            component: <NewsletterPopup />,
            position: 36,
            name: NEWSLETTER_POPUP,
        },
        {
            component: <WishListPriceAlertPopup />,
            position: 37,
            name: WISHLIST_PRICE_ALERT_POPUP,
        },
        {
            component: <CheckoutTermsAndConditionsPopup />,
            position: 38,
            name: CHECKOUT_TERMS_AND_CONDITIONS,
        },
        {
            component: <NotificationObserver />,
            position: 39,
            name: NOTIFICATION_OBSERVER,
        },
        {
            component: <RestoreScrollContainer />,
            position: 40,
            name: 'RestoreScrollContainer',
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={withStoreRegex('/search/:query/')} render={(props) => <SearchPage {...props} />} />,
            position: 25,
            name: SEARCH,
        },
        {
            component: <Route path={withStoreRegex('/page')} render={(props) => <CmsPage {...props} />} />,
            position: 40,
            name: CMS_PAGE,
        },
        {
            component: <Route path={withStoreRegex('/cart')} exact render={(props) => <CartPage {...props} />} />,
            position: 50,
            name: CART,
        },
        {
            component: <Route path={withStoreRegex('/checkout/:step?')} render={(props) => <Checkout {...props} />} />,
            position: 55,
            name: CHECKOUT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/createPassword/')}
                    render={(props) => <PasswordChangePage {...props} />}
                />
            ),
            position: 60,
            name: CHANGE_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/create/')}
                    render={(props) => <CreateAccountPage {...props} />}
                />
            ),
            position: 61,
            name: CREATE_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/login/')}
                    render={(props) => <LoginAccountPage {...props} />}
                />
            ),
            position: 62,
            name: LOGIN,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/forgotpassword/')}
                    render={(props) => <ForgotPasswordPage {...props} />}
                />
            ),
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: (
                <Route
                    path={[
                        withStoreRegex('/customer/account/confirm'),
                        withStoreRegex('/customer/account/confirmation'),
                    ]}
                    render={(props) => <ConfirmAccountPage {...props} />}
                />
            ),
            position: 65,
            name: CONFIRM_ACCOUNT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/view/order_id/:orderId?')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_ORDERS} />}
                />
            ),
            position: 70,
            name: MY_ACCOUNT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/history')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_ORDERS} />}
                />
            ),
            position: 71,
            name: MY_ACCOUNT_ORDERS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/downloadable/customer/products')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_DOWNLOADABLE} />}
                />
            ),
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/wishlist')}
                    exact
                    render={(props) => <MyAccount {...props} selectedTab={MY_WISHLIST} />}
                />
            ),
            position: 73,
            name: MY_ACCOUNT_WISHLIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/address')}
                    render={(props) => <MyAccount {...props} selectedTab={ADDRESS_BOOK} />}
                />
            ),
            position: 74,
            name: MY_ACCOUNT_ADDRESS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/newsletter/manage')}
                    render={(props) => <MyAccount {...props} selectedTab={NEWSLETTER_SUBSCRIPTION} />}
                />
            ),
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER,
        },
        {
            component: (
                <Route path={withStoreRegex('/customer/account/:tab?')} render={(props) => <MyAccount {...props} />} />
            ),
            position: 76,
            name: MY_ACCOUNT,
        },
        {
            component: <Route path={withStoreRegex('/menu')} render={(props) => <MenuPage {...props} />} />,
            position: 80,
            name: MENU,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/wishlist/shared/:code')}
                    render={(props) => <WishlistShared {...props} />}
                />
            ),
            position: 81,
            name: SHARED_WISHLIST,
        },
        {
            component: <Route path={withStoreRegex('/contact')} render={(props) => <ContactPage {...props} />} />,
            position: 82,
            name: CONTACT_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/print/order_id/:orderId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_ORDER_REQUEST} />}
                />
            ),
            position: 90,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printInvoice/order_id/:orderId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_ALL_INVOICES} />}
                />
            ),
            position: 91,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printShipment/order_id/:orderId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_ALL_SHIPMENT} />}
                />
            ),
            position: 92,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_ALL_REFUNDS} />}
                />
            ),
            position: 93,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_INVOICE} />}
                />
            ),
            position: 94,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_SHIPMENT} />}
                />
            ),
            position: 95,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?')}
                    render={(props) => <OrderPrintPage {...props} orderPrintRequest={PRINT_REFUND} />}
                />
            ),
            position: 95,
            name: PRINT_ORDER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account/forgotpasswordsuccess/')}
                    render={(props) => <ForgotPasswordSuccessPage {...props} />}
                />
            ),
            position: 64,
            name: ACCOUNT_FORGOT_PASSWORD_SUCCESS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/account-menu/')}
                    render={(props) => <MyAccountSidebarPage {...props} />}
                />
            ),
            position: 64,
            name: ACCOUNT_SIDEBAR_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(`/${translateUrl(BESTSELLERS_PAGE_URL)}`)}
                    render={(props) => <BestsellersPage {...props} />}
                />
            ),
            position: 65,
            name: BESTSELLERS_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/consents')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_CONSENTS} />}
                />
            ),
            position: 76,
            name: MY_ACCOUNT_CONSENTS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/reviews')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_REVIEWS} />}
                />
            ),
            position: 77,
            name: MY_ACCOUNT_REVIEWS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/reviews/:reviewId')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_REVIEWS} />}
                />
            ),
            position: 77,
            name: MY_ACCOUNT_REVIEWS_DETAILS_PAGE,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/order/repayview/order_id/:orderId/hash/:hash')}
                    render={(props) => <MyAccount {...props} selectedTab={MY_ORDERS} />}
                />
            ),
            position: 78,
            name: MY_ACCOUNT_ORDER_REPAYMENT,
        },

        {
            component: (
                <Route
                    exact
                    path={withStoreRegex('/cards/:key')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_CUSTOMER_CARDS_LIST} />}
                />
            ),
            position: 78,
            name: MY_ACCOUNT_CUSTOMER_CARDS_LIST,
        },
        {
            component: (
                <Route
                    exact
                    path={withStoreRegex('/card/new/:key')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_CUSTOMER_CARD_ADD} />}
                />
            ),
            position: 79,
            name: MY_ACCOUNT_CUSTOMER_CARD_ADD,
        },

        {
            component: (
                <Route
                    exact
                    path={withStoreRegex('/cards')}
                    render={(props) => <MyAccount {...props} selectedTab={ACCOUNT_CUSTOMER_CARDS} />}
                />
            ),
            position: 80,
            name: MY_ACCOUNT_CUSTOMER_CARDS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/ols-giftcard/giftcard/success/orderId/:orderId')}
                    render={(props) => <GiftCardsOrderSuccess {...props} />}
                />
            ),
            position: 81,
            name: GIFT_CARDS_ORDER_SUCCESS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/customer/invoice/information')}
                    render={(props) => <InvoiceInformation {...props} />}
                />
            ),
            position: 82,
            name: INVOICE_INFORMATION,
        },
        {
            component: (
                <Route path={withStoreRegex('/loginasuser/:hash')} render={(props) => <LoginAsCustomer {...props} />} />
            ),
            position: 83,
            name: LOGIN_AS_USER,
        },
        {
            component: <Route render={(props) => <UrlRewrites {...props} />} />,
            position: 1000,
            name: URL_REWRITES,
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER,
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP,
        },
        {
            component: <EdroneContainer />,
            name: EDRONE_CONTAINER,
            position: 30,
        },
        {
            component: <ClickonometricsContainer variant={CLICKONOMETRICS_VARIANT.MAIN} />,
            name: CLICKONOMETRICS_CONTAINER,
            position: 40,
        },
    ];

    state = {
        hasError: false,
        errorDetails: {},
    };

    componentDidCatch(err, info) {
        this.setState({
            hasError: true,
            errorDetails: { err, info },
        });
    }

    getSortedItems(type) {
        return this[type]
            .sort((a, b) => a.position - b.position)
            .filter((entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');

                    return false;
                }

                return true;
            });
    }

    handleErrorReset() {
        this.setState({ hasError: false });
    }

    renderScrollToTop() {
        return <ScrollToTop />;
    }

    renderComponentsOfType(type) {
        return this.getSortedItems(type).map(({ position, component }) => cloneElement(component, { key: position }));
    }

    renderSectionOfType(type) {
        return <Suspense fallback={<Loader isLoading />}>{this.renderComponentsOfType(type)}</Suspense>;
    }

    renderMainItems() {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <OfflineNotice isPage />;
        }

        return <Switch>{this.renderComponentsOfType(SWITCH_ITEMS_TYPE)}</Switch>;
    }

    renderErrorRouterContent() {
        const { errorDetails } = this.state;

        return <SomethingWentWrong onClick={this.handleErrorReset} errorDetails={errorDetails} />;
    }

    renderFallbackPage() {
        return (
            <main block="Router" elem="Loader">
                <Loader isLoading />
            </main>
        );
    }

    renderDefaultRouterContent() {
        if (location.pathname.match('/styleguide')) {
            return this.renderMainItems();
        }

        return (
            <>
                {this.renderSectionOfType(BEFORE_ITEMS_TYPE)}
                {this.renderMainItems()}
                {this.renderSectionOfType(AFTER_ITEMS_TYPE)}
                {this.renderScrollToTop()}
            </>
        );
    }

    renderRouterContent() {
        const { hasError } = this.state;

        if (hasError) {
            return this.renderErrorRouterContent();
        }

        return this.renderDefaultRouterContent();
    }

    render() {
        return (
            <CmsBlockProvider
                identifiers={[
                    HOMEPAGE_SLIDER_CMS_ID,
                    HOMEPAGE_USP_CMS_ID,
                    FOOTER_COPYRIGHT_CMS_ID,
                    FOOTER_LINKS_CMS_ID,
                    FOOTER_PAYMENTS_CMS_ID,
                    FOOTER_SEO_CMS_ID,
                    FOOTER_SOCIAL_ICONS_CMS_ID,
                    NEWSLETTER_INFO_CMS_ID,
                    MOBILE_MENU_LINKS_CMS_ID,
                ]}
            >
                <Meta />
                <ReactRouter history={history}>{this.renderRouterContent()}</ReactRouter>
            </CmsBlockProvider>
        );
    }
}

export default Router;
