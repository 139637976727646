import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { ACCOUNT_LOGIN_AS_CUSTOMER_URL } from 'Route/MyAccount/MyAccount.config';
import { CUSTOMER, MyAccountDispatcher as SourceMyAccountDispatcher } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import CookieDispatcher from 'Store/Cookie/Cookie.dispatcher';
import { updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken, isSignedIn, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';

export { ONE_MONTH_IN_SECONDS } from 'SourceStore/MyAccount/MyAccount.dispatcher';
export { CUSTOMER };

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace SwiatKsiazkiBasic/Store/MyAccount/Dispatcher/isLoginAsCustomer */
export const isLoginAsCustomer = () => history.location.pathname.includes(ACCOUNT_LOGIN_AS_CUSTOMER_URL);

/** @namespace SwiatKsiazkiBasic/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    async loginAsUser(hash, dispatch) {
        try {
            const { loginAsUser: { token } = {} } = await executePost(
                prepareQuery(MyAccountQuery.getLoginAsUserQuery(hash))
            );

            if (!token) {
                return Promise.reject(new Error(__('Token is invalid')));
            }

            setAuthorizationToken(token);

            ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

            const cartDispatcher = (await CartDispatcher).default;
            const guestCartToken = getGuestQuoteId();
            // if customer is authorized, `createEmptyCart` mutation returns customer cart token
            const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

            if (guestCartToken && guestCartToken !== customerCartToken) {
                // merge guest cart id and customer cart id using magento capabilities
                await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
            }

            setGuestQuoteId(customerCartToken);
            cartDispatcher.updateInitialCartData(dispatch, true);

            WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

            await this.requestCustomerData(dispatch);

            dispatch(updateCustomerSignInStatus(true));
            dispatch(updateIsLoading(false));
            dispatch(hideActiveOverlay());
            dispatch(showNotification('success', __('You are successfully logged in!')));
        } catch (error) {
            if (error instanceof Error) {
                return Promise.reject(error.message);
            }

            return Promise.reject(getErrorMessage(error));
        }
    }

    createAccount(options = {}, dispatch) {
        const {
            customer: { email },
            password,
        } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace SwiatKsiazkiBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
            (data) => {
                const {
                    createCustomer: { customer },
                } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace SwiatKsiazkiBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        if (authTokenExpired) {
            if (isWithNotification) {
                dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }

            this.handleForceRedirectToLoginPage();
        } else {
            if (isSignedIn()) {
                fetchMutation(MyAccountQuery.getRevokeAccountToken());
                deleteAuthorizationToken();
            }

            if (isWithNotification) {
                dispatch(showNotification('success', __('You are successfully logged out!')));
            }
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        });
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.resetWishlist(dispatch));

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    }

    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const {
            generateCustomerToken: { token },
        } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        CookieDispatcher.saveGroupsOnRegister();
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }
}

export default new MyAccountDispatcher();
