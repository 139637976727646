import Image from 'Component/Image/Image.component';
import { IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED } from 'Component/Image/Image.config';
import { Logo as SourceLogo } from 'SourceComponent/Logo/Logo.component';
import logo from 'Style/icons/logos/mainLogo.svg';

import './Logo.override.style';
import './Logo.style';

/** @namespace SwiatKsiazkiBasic/Component/Logo/Component */
export class Logo extends SourceLogo {
    renderPlaceholderLogo() {
        const { onImageLoad } = this.props;

        return (
            <div block="Logo" elem="Placeholder">
                <Image
                    src={logo}
                    alt="LogoPlaceholder"
                    ratio="custom"
                    onImageLoad={onImageLoad}
                    fetchPriority="high"
                    lazyLoadingMode="eager"
                    useNativeLazyLoading
                />
            </div>
        );
    }

    renderImage() {
        const { imageStatus } = this.state;
        const { src } = this.props;

        if (!src) {
            return this.renderPlaceholderLogo();
        }

        switch (imageStatus) {
            case IMAGE_NOT_FOUND:
            case IMAGE_NOT_SPECIFIED:
                return this.renderPlaceholderLogo();
            default:
                return super.renderImage();
        }
    }

    render() {
        const { imageStatus } = this.state;

        return (
            <div block="Image" mods={{ imageStatus }} mix={{ block: 'Logo' }}>
                {this.renderImage()}
            </div>
        );
    }
}

export default Logo;
