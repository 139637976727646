import { connect } from 'react-redux';

import { EdroneObservable, EVENT_ADD_TO_CART } from 'Component/Edrone';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform, magentoProductWishlistTransform } from 'Util/Product/Transform';

export { mapStateToProps, mapDispatchToProps };

/** @namespace SwiatKsiazkiBasic/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const { variant, size, shortText, label } = this.props;

        return {
            ...super.containerProps(),
            variant,
            size,
            shortText,
            label,
        };
    }

    async addProductToCart(e) {
        const { product, addToCart, isWishlistItem, wishlistItemRemoveAction } = this.props;

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart();

                EdroneObservable.send(EVENT_ADD_TO_CART, product);

                if (isWishlistItem) {
                    wishlistItemRemoveAction();
                }
            } finally {
                this.setState({ isAdding: false });
            }
        } else {
            const { quantity, cartId, fallbackAddToCart } = this.props;
            const magentoProduct = isWishlistItem
                ? magentoProductWishlistTransform(ADD_TO_CART, product, quantity)
                : magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId,
                });

                EdroneObservable.send(EVENT_ADD_TO_CART, product);

                if (isWishlistItem) {
                    wishlistItemRemoveAction();
                }
            } finally {
                this.setState({ isAdding: false });
            }
        }

        this.setState({ isAdding: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
