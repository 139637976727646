import Icon from 'Component/Icon';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace SwiatKsiazkiBasic/Component/Popup/Component */
export class Popup extends SourcePopup {
    renderCloseButton() {
        const { showCloseBtn } = this.props;

        if (!showCloseBtn) {
            return null;
        }

        return (
            <button block="Popup" elem="CloseBtn" aria-label={__('Close')} onClick={this.hidePopupAndGoBack}>
                <Icon name="close" width="24" height="24" fill="#A2A2A2" />
            </button>
        );
    }

    hidePopUp() {
        const { hideActiveOverlay, goToPreviousNavigationState, noHeaderStateChange, onClose } = this.props;
        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();
            if (!noHeaderStateChange) {
                goToPreviousNavigationState();
            }
            onClose();
        }
    }

    // Same with click outside
    handleClickOutside() {
        const { clickOutside } = this.props;

        if (!clickOutside) {
            return;
        }

        this.hidePopupAndGoBack();
    }
}

export default Popup;
